<template>
    <v-container fluid>
        <div v-if="!studentListPage">
            <v-row justify='space-between' class="pb-5">
                <v-col>
                    <h1>CMU Exam</h1>
                </v-col>
                <!-- term Course {{courseData.semester }} | term Curresnt {{ currentSemester }} -->
                <v-col class="text-right"  v-if=" currentSemester === courseData.semester || yearlySemester === courseData.semester || nextSemester === courseData.semester  ">
                <!-- <v-col class="text-right"  v-if="false"> -->
                    <CreateCMUExamModal  :courseData="courseData" @modalSubmit="onModalSubmit" :openModal="openCreateModal" @modalClose="openCreateModal = false"/>
                </v-col>
            </v-row>
        
            <v-card class="mt-4">
                <v-card-text>
                    <v-row>
                        <v-col v-if="examList.length > 0">
                            <div v-for="(exam, i) in examList" :key="i">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-circle-small</v-icon>
                                            {{`${exam.title} (${sectionAfterTitleName(exam.sections)})`}}
                                            <!-- <i class="fas fa-external-link-alt"></i> -->
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>

                                        <v-row lign="center"
                                        justify="center">
                                        
                                            <v-col>
                                                <v-btn class="mr-1 white--text"  :color="jobStatusBtn(exam.jobStatus)" @click="showDetailcreating(exam)">
                                                    {{jobStatus(exam.jobStatus)}}
                                                    <!-- {{exam.jobStatus}} -->
                                                    <i class="fas fa-spinner fa-pulse  fa-spin fa-fw" v-if="exam.jobStatus !== 'created' && exam.jobStatus !== 'error'"></i>
                                                </v-btn>
                                            </v-col>
                                            <v-col>
                                                <v-menu
                                                    bottom
                                                    left
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        >
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click="clickEdit">
                                                            <v-list-item-title><v-icon>mdi-square-edit-outline</v-icon> Edit</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click="clickDelete">
                                                            <v-list-item-title><v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <a>
                                                     <v-img
                                                        @click="onClickStudent(exam)"
                                                        class="mt-2" src="../assets/icon/studentList.png">
                                                    </v-img>
                                                </a>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-icon>
                                </v-list-item>               
                                <!-- <v-divider v-if="i+1 !== examList.length"></v-divider> -->
                            </div>
                        </v-col>
                        <v-col v-else class="text-center" style="border-style: dashed;border-radius: 5px;cursor: pointer;" @click="onOpenCreatemodal">
                            <i class="fas fa-plus fa-6x"></i>
                            <h2>Create CMU Exam</h2>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <div v-else>
            <v-row justify='space-between'>
                <v-col cols="2">
                </v-col>
                <v-col cols="8">
                    <h1>{{ examOnStudentList.title }} Student list </h1>
                    <v-row class="mt-3">
                        <v-expansion-panels v-model="studentPanel" multiple :key="panelKey">
                            <v-expansion-panel v-for="(section , idx) in examOnStudentList.sections" :key="idx" class="mt-3" :id="idx">
                                <v-expansion-panel-header class="violet-outlined">
                                    <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bolder;">
                                            <h3 style="font-weight: bolder;">{{filterSection(section)}}</h3>
                                            <!-- <i class="fas fa-external-link-alt"></i> -->
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                    <!-- <h3 style="font-weight: bolder;">{{`(${sectionAfterTitleName(courseData.sections)})`}}</h3> -->
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row>
                                        <v-col>
                                            <template>
                                            <v-data-table
                                                :headers="headers"
                                                :items="examOnStudentList.students[section].data"
                                                item-value='studentList_${section}'
                                                class="elevation-0"
                                            >
                                                <template v-slot:item.no="{item, index}">
                                                    <tr>
                                                        <td class="text-center">{{index + 1}}</td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </template>
                                        </v-col>
                                       
                                    
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        
                        </v-expansion-panels>
                    </v-row>
                </v-col>
                <v-col cols="2">
                </v-col>
            </v-row>
            
        </div>
        <ModalNotifyInfoCreateFlow :showModal="modalCreate" :openWeb="openWeb" :sectionCompleteShow="createComplete" :sectionUncompleteShow="createUncomplete" @onClose="onClose"></ModalNotifyInfoCreateFlow>
        <!-- <ModalRemoveCourseCMUExam  :showModal="showNotify" :textTitle="textNotifyTitle" :textDetail="textNotifyDetail" :openWeb="openWebNotify" @onClose = "onClose" @onDelete = "onHandleDelete"/> -->
                
        
        <course-detail-component />
    </v-container>
</template>

<script>
    import CourseDetailComponent from '../components/CourseDetailComponent'
    import CreateCMUExamModal from '../components/CMUExam/CreateCMUExamModal'
    import apiGatewayCpmService from "@/services/apiGatewayService"
    import apiLmsManagementService from "@/services/apiLmsManagementService"
    import ModalNotifyInfoCreateFlow from "../components/ModalNotification/ModalNotifyInfoCreateFlow.vue"
    // import ModalRemoveCourseCMUExam from '../components/ModalRemoveCourse/ModalRemoveCourseCMUExam.vue'
    import Swal from 'sweetalert2'
    const config = require(`@/config`)

    export default {
    name: 'CourseCMUExam',
    components: {
        CourseDetailComponent,
        CreateCMUExamModal,
        ModalNotifyInfoCreateFlow,
        // ModalRemoveCourseCMUExam
    },
    data() {
        return {
            panel: 0,
            examList: [],
            openCreateModal: false,
            intervalStatus: "",
            createComplete: [] , 
            createUncomplete : [] ,
            modalCreate : false,
            openWeb : '' , 
            studentListPage : false , 
            examOnStudentList : {} , 
            studentPanel : [] , 
            panelKey : 0 , 
            headers: [
                {
                    text: 'No',
                    align: 'left',
                    sortable: false,
                    value: 'no',
                },
                { text: 'Student ID', align: 'left', value: 'idnumber' , width: "130px"},
                { text: 'Name', align: 'left', value: 'fullname' },
                { text: 'Email', align: 'left', value: 'email' },
            ],
        }
    },
    computed: {
        courseData: {
            get () {
                return this.$store.state.sideBarData
            },
            set () {
                this.$store.commit('setSideBarData')
            }
        },
        userData: {
            get () {
                return this.$store.state.userData
            },
            set () {
                this.$store.commit('setUserData')
            }
        },
        currentSemester() {
            return this.$store.state.currentSemester
        },
        nextSemester() {
            return this.$store.state.nextSemester
        },
        yearlySemester() {
            return this.$store.state.yearlySemester
        }
    },
    watch:{
        async studentPanel(){
            this.panelKey +=1 ;
        }
    },
    created () {
        Swal.fire({
            text: 'loading...',
            allowOutsideClick: false
        })
        Swal.showLoading()
        // console.log("courseId:>", this.$route.params.courseId)
        this.getCMUExamModule(this.$route.params.courseId, true)
    },
    beforeDestroy() {
        clearInterval(this.intervalStatus)
    },
    methods: {
        onClickStudent(exam){
           
            clearInterval(this.intervalStatus)
            
            let studentList = {}
            for(let i=0;i<exam.sections.length;i++){
                studentList[exam.sections[i].toString()] = {}
            }
            exam["students"] = studentList
            this.examOnStudentList = exam 
            this.studentListPage = true
            this.getListStudents(exam._id , "all")
        },
        async getListStudents(mappingId , section){
            Swal.fire({
                text: 'loading...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            let res = await apiGatewayCpmService.getStudentBySection(localStorage.getItem("accessToken")  , mappingId , section)
            for(let i = 0 ; i < res.length ; i++){
                for(let j = 0 ; j  < Object.keys(this.examOnStudentList.students).length ; j++){
                    if (res[i].section ===  Object.keys(this.examOnStudentList.students)[j]){
                        this.examOnStudentList.students[Object.keys(this.examOnStudentList.students)[j]] = res[i]
                        break
                    }
                }
               
            }           
            Swal.close()
            for(let j = 0 ; j  < Object.keys(this.examOnStudentList.students).length ; j++){
                if(this.examOnStudentList.students[Object.keys(this.examOnStudentList.students)[j]]){
                    this.studentPanel.push(j)
                    // this.$vuetify.goTo(`#${j}`)
                    break
                }
            }
            
                
            console.log("this.showDefault" , this.studentPanel )
            this.studentListPage = true
        },
        onClose(){
            
            this.modalCreate = false
        },
        greetings(text) {
          console.log('Good Morning!',text);
        },
        async getCMUExamModule (courseId, closeLoading = false) {
            let tmpList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
            // console.log("list :>", tmpList)
            // check  list service is exam 
            if(tmpList){
                let _tmpList = []
                for(let i=0;i<tmpList.length;i++){
                    if (tmpList[i].service === "exam"){
                        _tmpList.push(tmpList[i])
                    }
                }
                this.examList = _tmpList //Exam filter service "exam"
                console.log("lms exam" , this.examList)

                this.intervalStatus = setInterval(async () => {
                    let tmpList = await apiGatewayCpmService.getCourseMappingList(courseId,"lms",localStorage.getItem("accessToken"))
                    let _tmpList = []
                    for(let i=0;i<tmpList.length;i++){
                        if (tmpList[i].service === "exam"){
                            _tmpList.push(tmpList[i])
                        }
                    }   
                    
                    this.examList = _tmpList
                }, 5000, this.examList)
            }
            if(closeLoading) Swal.close()
            Swal.close()
        },
        showDetailcreating (createStatus) {
            // console.log(`createStatus:${createStatus.status}`)
            switch (createStatus.jobStatus) {
                case "created":
                    console.log("go to Exam")
                    window.open(`${createStatus.webUrl}`,'_blank')
                    break

                case "failed":
                    Swal.fire({
                        icon: 'error',
                        title: 'ระบบเกิดข้อพลาด',
                        html: 'เกิดข้อผิดพลาดในการสร้าง microsoft teams <br>กรุณาติดต่อเจ้าหน้าที่',
                        showCloseButton: true,
                        confirmButtonText: '<i class="fas fa-comments"></i> ติดต่อเจ้าหน้าที่',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.open(`https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1`,'_blank')
                        }
                    })
                    break
                default:
                    Swal.fire({
                        icon: 'info',
                        title: 'กำลังดำเนินการสร้าง',
                        text: 'ระบบอาจใช้เวลาสักครู่ในการประมวลผล'
                    })
            }
        },
        sectionAfterTitleName (sectionArr) {
            let sectionTmp = []
            for(let i=0;i<sectionArr.length;i++){
                sectionTmp.push(this.filterSection(sectionArr[i]))
            }
            return sectionTmp
        },
        filterSection (section) {
            let sectionLec = section.substring(0, 3)
            let sectionLab = section.substring(3)
            if (sectionLec === '000' && sectionLab !== '000') {
                return `Lab.${sectionLab}`
            }else if(sectionLec !== '000' && sectionLab === '000'){
                return `Lec.${sectionLec}`
            }
            return section
        },
        onOpenCreatemodal () {
            this.openCreateModal = true
        },
        async onModalSubmit (val , typeCreate) {
            console.log("Create Exam :" , val , typeCreate)
            Swal.fire({
                text: 'loading...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            let formData ={}
            if(this.courseData.semester  === config.lmsConfig.currentSemester){
                formData ={
                    "courseId": this.$route.params.courseId,
                    "termDateStart": config.lmsConfig.moodle.termStart,
                    "termDateEnd": config.lmsConfig.moodle.termEnd,
                    "sections": val
                }
            }else if(this.courseData.semester  === config.lmsConfig.nextSemester){
                formData ={
                    "courseId": this.$route.params.courseId,
                    "termDateStart": config.lmsConfig.moodleNext.termStart,
                    "termDateEnd": config.lmsConfig.moodleNext.termEnd,
                    "sections": val
                }

            }else if(this.courseData.semester  === config.lmsConfig.yearlySemester){
                formData ={
                    "courseId": this.$route.params.courseId,
                    "termDateStart": config.lmsConfig.moodleYearly.termStart,
                    "termDateEnd": config.lmsConfig.moodleYearly.termEnd,
                    "sections": val
                }
                
            }
            let createLmsExam = []
            
            if(typeCreate === "sumAll" || val.length === 1){
              createLmsExam = await apiGatewayCpmService.CreateLmsExam(localStorage.getItem("accessToken") , formData);
              if(createLmsExam.status){
                this.createComplete = [val]
                this.createUncomplete = []
                this.modalCreate = true
                this.openWeb = `/courses/${this.$route.params.courseId}/exam`
                Swal.close()
              }else{
                this.createComplete = []
                this.createUncomplete = [{"section" : val , "error" : String(createLmsExam?.message)}]
                this.modalCreate = true
                this.openWeb = `/courses/${this.$route.params.courseId}/exam`
                Swal.close()
              }
            }else if(typeCreate === "splitAll"){
              val = val.sort()
              let _createComplete = []
              let _createUncomplete = []
              for(let i = 0 ; i < val.length ; i++){
                if(this.courseData.semester === config.lmsConfig.currentSemester){
                    formData ={
                        "courseId": this.$route.params.courseId,
                        "termDateStart": config.lmsConfig.moodle.termStart,
                        "termDateEnd": config.lmsConfig.moodle.termEnd,
                        "sections": [val[i]]
                    }
                }else if(this.courseData.semester === config.lmsConfig.nextSemester){
                    formData ={
                        "courseId": this.$route.params.courseId,
                        "termDateStart": config.lmsConfig.moodleNext.termStart,
                        "termDateEnd": config.lmsConfig.moodleNext.termEnd,
                        "sections": [val[i]]
                    }

                }else if(this.courseData.semester === config.lmsConfig.yearlySemester){
                    formData ={
                        "courseId": this.$route.params.courseId,
                        "termDateStart": config.lmsConfig.moodleYearly.termStart,
                        "termDateEnd": config.lmsConfig.moodleYearly.termEnd,
                        "sections": [val[i]]
                    }
                    
                }
                createLmsExam = await apiGatewayCpmService.CreateLmsExam(localStorage.getItem("accessToken") , formData);
                if(createLmsExam.status){
                  _createComplete.push(val[i])
                }else{
                  _createUncomplete.push({"section" : val[i] , "error" : String(createLmsExam?.message)})
                }

              }
              this.createComplete = _createComplete
              this.createUncomplete = _createUncomplete
              this.modalCreate = true
              this.openWeb = `/courses/${this.$route.params.courseId}/exam`
              Swal.close()
            }
            
            
        },
        alertError (errorMessage) {
            let text = ""
            switch (errorMessage) {
                case "mappingHasReadyExit":
                    text = "Course already exist"
                    break
                case "notHavePermission":
                    text = "Don't have permission for mango"
                    break
                default:
                    text = "error"
                    break
            }
            Swal.fire({
                icon: 'error',
                title: 'Create fail',
                text: `${text}`,
            })
        },
        async updateWebUrl (mappingId, url) {
            const reqBody = {
                "webUrl": url
            }
            const resUpdateWebUrl = await apiGatewayCpmService.updateMapping(this.courseData._id, mappingId, reqBody, localStorage.getItem("accessToken"))
            return resUpdateWebUrl
        },
        // async createLms (createData) {
        //     // console.log(createData)
        //     let resCreate = {}
        //     let createObj = {}
        //     let courseData = this.courseData
        //     let userData = this.userData
        //     let semesterSplit = courseData.semesterFull.split("/")
        //     // let resCheckGrant = false     
        //     if(semesterSplit[0] === "1"){
        //         console.log("create term 1")
        //         createObj = {
        //             "term": semesterSplit[0],
        //             "year": semesterSplit[1],
        //             "term_start": config.lmsConfig.examPrev.termStart,
        //             "term_end": config.lmsConfig.examPrev.termEnd,
        //             "regCourse_selected_id": courseData.courseNumber,
        //             "regCourse_selected_name": courseData.title,
        //             "sectionArr": createData,
        //             "category_selected" : config.lmsConfig.exam.categoryId,
        //             "create_by": 
        //             {
        //                 "cmuitaccount_name" : userData.email.split('@')[0],
        //                 "cmuitaccount" : userData.email
        //             }
        //         }
        //     }else{
        //         console.log("create term2")
        //         createObj = {
        //             "term": semesterSplit[0],
        //             "year": semesterSplit[1],
        //             "term_start": config.lmsConfig.exam.termStart,
        //             "term_end": config.lmsConfig.exam.termEnd,
        //             "regCourse_selected_id": courseData.courseNumber,
        //             "regCourse_selected_name": courseData.title,
        //             "sectionArr": createData,
        //             "category_selected" : config.lmsConfig.exam.categoryId,
        //             "create_by": 
        //             {
        //                 "cmuitaccount_name" : userData.email.split('@')[0],
        //                 "cmuitaccount" : userData.email
        //             }
        //         }

        //     }       
           
        //     // console.log("body" , createObj)
        //     resCreate = await apiLmsManagementService.createExam(createObj)
        //     if(resCreate['status']){
        //         console.log("create lms success")
        //         resCreate['webUrl'] = `https://exam.cmu.ac.th/course/view.php?id=${resCreate[0].id}`
        //     }
        //     return resCreate
        //     // return "test"
                
        // },
        async updateActivate () {
            await this.getCMUExamModule(this.$route.params.courseId)
            const reqBody = {
                "activated": true
            }
            for(let i=0;i<this.examList.length;i++){
                if(!this.examList[i].activated){
                    // console.log("create")
                    await apiGatewayCpmService.updateMapping(this.examList[i].courseid, this.examList[i]._id, reqBody, localStorage.getItem("accessToken"))
                    // if(resUpdateActivate.status){
                    //     console.log(resUpdateActivate)
                    // }
                }
            }
            this.getCMUExamModule(this.$route.params.courseId)
        },
       
        async getJobStatus (examList) {
            for(let i=0;i<examList.length;i++){
                if(examList[i].webUrl !== ""){
                    examList[i].job = await apiLmsManagementService.getJobStatus("exam", examList[i].webUrl.split("https://exam.cmu.ac.th/course/view.php?id=")[1])
                }else{
                    examList[i].job = {
                        job_status: "creating"
                    }
                }
            }
            this.examList = examList
            // console.log("job" ,  this.examList)
        },
        jobStatus (status) {
            switch (status) {
                case 'new': return 'creating'
                case 'failed': return 'error'
                case 'creating': return 'creating'
                case 'created': return 'Go to Exam'
                default: return 'not found'
            }
        },
        jobStatusBtn (status) {
            // console.log("check jobStatusBtn:>", status.job.job_status)
            switch (status) {
                case "created":
                    return "#745CA8"
                case "error":
                    return "red"
                default:
                    return "#745CA8"
            }
        },
        clickDelete () {
            Swal.fire({
                icon: 'warning',
                title: 'Please delete in Exam application',
                text: `ตอนนี้ระบบยังไม่รองรับการลบ Exam จึงแนะนำให้ท่านลบผ่าน Exam application`,
                showCancelButton: true,
                confirmButtonText: 'Go to manual',
                cancelButtonText: 'Close'
            })
        },
        clickEdit () {
            Swal.fire({
                icon: 'warning',
                title: 'Please delete Exam and create new one again',
                text: `ตอนนี้ระบบยังไม่รองรับการแก้ไข จึงแนะนำให้ท่านลบที่ Exam ที่ท่านต้องการและแนะนำให้ท่านสร้าง Exam ขึ้นใหม่`,
                showCancelButton: true,
                confirmButtonText: 'Go to manual',
                cancelButtonText: 'Close'
            })
        }
    },
    }
</script> 
