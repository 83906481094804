<template>
    <v-container fluid>
        <!-- <v-navigation-drawer
            v-model="drawer"
            absolute
        >
        <v-list-item>
            <v-list-item-avatar>
            <v-img src="https://randomuser.me/api/portraits/men/78.jpg"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
            <v-list-item-title>John Leider</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>

            <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            >
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            </v-list-item>
        </v-list>
        </v-navigation-drawer> -->
        <!-- <v-row
            align="center"
            justify="center"
            class="py-8 px-6"
        >
            <v-btn
            color="pink"
            dark
            @click.stop="toggleSideBar"
            >
            Toggle
            </v-btn>
        </v-row> -->
    </v-container>
</template>

<script>
    export default {
        name: 'CourseDetailComponent',
        components: {
        },
        mounted () {
            this.drawer = this.$store.state.sideBarIsShow
        },
        created () {
            // console.log(this.$store.state.sideBarIsShow)
        },
        data: () => ({
            drawer: true,
            items: [
            { title: 'Home', icon: 'mdi-home-city' },
            { title: 'My Account', icon: 'mdi-account' },
            { title: 'Users', icon: 'mdi-account-group-outline' },
            ],
        }),
        computed: {

        },
        methods: {
            toggleSideBar () {
                this.$store.commit('togglesideBar')
            }
        },
    }
</script>
