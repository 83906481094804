<template>
    <v-row justify="center">
        <v-dialog
            v-model="showModal"
            max-width="600"           
        >   
            <v-card justify="center" class="text-center"   full-width  v-if="sectionUncompleteShow.length === 0">
                <v-card-content>
                    <v-col>
                        <v-icon size="120" class="green--text py-5">mdi-check-circle-outline</v-icon>
                        <v-row align="center" justify="center" style="font-size: 30px; font-weight: bolder;">การสร้างสำเร็จ !</v-row>
                        <v-row class="mt-5" align="center" justify="center">
                            <strong  class="black--text text--bold mr-3" style="opacity: 50%;">จำนวน {{sectionCompleteShow.length}} รายการ จาก {{sectionCompleteShow.length}} รายการ</strong > 
                        </v-row>
                    </v-col>
                    <v-col class="mt-4">
                        <v-btn
                            width="140"
                            class="white--text"
                            color="#69A5FF"
                            @click="onOpen()"
                        >
                        ดูข้อมูลเพิ่มเติม
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            style="margin-top: -1.5rem ;" 
                            text
                            @click="onClose()"
                        >
                        ปิด
                        </v-btn>
                        
                    </v-col>
                </v-card-content>
            </v-card>
            <v-card justify="center" class="text-center"  full-width v-else-if="sectionCompleteShow.length !== 0 && sectionUncompleteShow.length !== 0 ">
                <v-card-content>
                    <!-- <i class="fa-regular fa-circle-check"></i> -->
                    <v-col>
                        <i class="fa-solid fa-triangle-exclamation" style="color: #f8c53a;padding-top: 3rem; font-size: 120px;"></i>                    
                        <v-row align="center" justify="center" style="font-size: 30px; font-weight: bolder; padding-top: 1rem;">การสร้างมีปัญหา</v-row>
                        <v-row class="mt-5" align="center" justify="center">
                            <strong  class="black--text text--bold mr-3" style="opacity: 50%;">จำนวน {{sectionUncompleteShow.length}} รายการ จาก {{sectionCompleteShow.length + sectionUncompleteShow.length}} รายการ</strong > 
                        </v-row>
                        <v-row class="mt-5" align="center" justify="center">
                        
                            <v-col class="mr-3">
                                <div v-if="sectionCompleteShow.length > 0">
                                    <v-btn text @click="showLmsComplete = !showLmsComplete" >
                                        <b style="text-decoration:inline;">
                                            <a style="color: #89888b;">
                                            แสดงรายการ
                                            </a>
                                        </b>
                                        <v-icon v-if="showLmsComplete" style="color: #89888b;">mdi-chevron-up</v-icon>
                                        <v-icon v-else   style="color: #89888b;">mdi-chevron-down</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <transition name="slide-fade">
                        <v-row v-if="showLmsComplete" class="text-center ml-5 mr-5">
                            <v-col>
                                <template>
                                    <v-data-table
                                        :items-per-page="itemsPerPage"
                                        :headers="headers"
                                        :items="sectionUncompleteShow"
                                        item-value="name"
                                        class="elevation-1"
                                    >
                                        <template v-slot:item.no="{item, index}">
                                            <tr>
                                                <td class="text-center">{{index + 1}}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </template>
                            </v-col>
                        </v-row>
                    </transition>
                    <v-col class="mt-4">
                        <v-btn
                            width="140"
                            class="white--text"
                            color="#69A5FF"
                            @click="onOpen()"
                            v-if="openWeb === ''"
                        >
                            ติดต่อเจ้าหน้าที่
                        </v-btn>
                        <v-btn
                            width="140"
                            class="white--text"
                            color="#69A5FF"
                            @click="onOpen()"
                            v-if="openWeb !== ''"
                        >
                            ดูรายละเอียดวิชา
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            style="margin-top: -1.5rem ;" 
                            text
                            @click="onClose()"
                        >
                        ปิด
                        </v-btn>
                        
                    </v-col>
                </v-card-content>
            </v-card>
            <v-card justify="center" class="text-center"  full-width  v-else-if="sectionCompleteShow.length === 0">
                <v-card-content>
                    <v-col>
                        <i class="fa-regular fa-circle-xmark" style="color: #f33030; font-size: 120px;  padding-top: 4rem;"></i>
                        <v-row align="center" justify="center" style="font-size: 30px; font-weight: bolder; padding-top: 1rem;">การสร้างไม่สำเร็จ</v-row>
                        <v-row class="mt-5" align="center" justify="center">
                            <strong  class="black--text text--bold mr-3" style="opacity: 50%;">จำนวน {{sectionUncompleteShow.length}} รายการ จาก {{sectionCompleteShow.length + sectionUncompleteShow.length}} รายการ</strong > 
                        </v-row>
                        <v-row class="mt-5" align="center" justify="center">
                            <v-col class="mr-3">
                                <div v-if="sectionUncompleteShow.length > 0">
                                    <v-btn text @click="showLmsComplete = !showLmsComplete" >
                                        <b style="text-decoration:inline;">
                                            <a style="color: #89888b;">
                                            แสดงรายการ
                                            </a>
                                        </b>
                                        <v-icon v-if="showLmsComplete" style="color: #89888b;">mdi-chevron-up</v-icon>
                                        <v-icon v-else   style="color: #89888b;">mdi-chevron-down</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    
                    <transition name="slide-fade">
                        <v-row v-if="showLmsComplete" class="text-center ml-5 mr-5">
                            <v-col>

                                <template>
                                    <v-data-table
                                        :items-per-page="itemsPerPage"
                                        :headers="headers"
                                        :items="sectionUncompleteShow"
                                        item-value="name"
                                        class="elevation-1"
                                    >
                                        <template v-slot:item.no="{item, index}">
                                            <tr>
                                                <td class="text-center">{{index + 1}}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </template>

                            </v-col>
                        </v-row>
                    </transition>
                    <v-col class="mt-4">
                        <v-btn
                            width="140"
                            class="white--text"
                            color="#69A5FF"
                            @click="onOpen()"
                            v-if="openWeb === ''"
                        >
                            ติดต่อเจ้าหน้าที่
                        </v-btn>
                        <v-btn
                            width="140"
                            class="white--text"
                            color="#69A5FF"
                            @click="onOpen()"
                            v-if="openWeb !== ''"
                        >
                            ดูรายละเอียดวิชา
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            style="margin-top: -1.5rem ;" 
                            text
                            @click="onClose()"
                        >
                        ปิด
                        </v-btn>
                        
                    </v-col>
                </v-card-content>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    export default {
        name : "ModalNotifyInfoCreateFlow",
        props:{
            showModal : Boolean,
            openWeb : String ,
            sectionCompleteShow : Array , 
            sectionUncompleteShow : Array
        },
        watch:{
            sectionCompleteShow(val , oldval){
                // this.syncStatus = !val
                console.log("sectionCompleteShow" , val ,oldval );        
            },
        },
        data: () => ({
            showLmsComplete : false,
            showLmsNoComplete : false,
            itemsPerPage : 5 ,
            headers: [
                {
                    text: 'No',
                    align: 'center',
                    sortable: false,
                    value: 'no',
                },
                { text: 'Section', align: 'center', value: 'section' },
                { text: 'Error', align: 'center', value: 'error' },
            ],
          
        }),
        created(){
        },
        methods:{
            onClose(){
                this.$emit('onClose')
            },
            onOpen(){
                // window.open(this.openWeb , '_blank')
                if(this.openWeb !== ""){
                    this.$router.push(this.openWeb)
                }else{
                    window.open("https://www.info.tlic.cmu.ac.th/contact-us" , '_blank')
                }
                this.$emit('onClose')
                return 0;
            }
        },
    }
</script>

